import Image from "@/components/common/Image";
import LokaliseMarkdownText from "@/components/text/LokaliseMarkdownText";
import { Heading } from "@/ui/cva/Heading";
import { Body } from "@/ui/typography/Body";
import { LinkButton } from "@/ui/next/LinkButton";
import HeartIcon from "@/assets/icons/24/heart/Filled";
import { analyticsService } from "@/common/services/analytics/analytics.service";
import { useTranslation } from "next-i18next";
import { Fragment } from "react";

export const HomepageMarketingBlock = () => {
  const { t } = useTranslation();

  const handleMarketingClick = () => {
    /** Trigger `hp_marketing_banner` event. */
    analyticsService.trackHpMarketingBanner();
  };

  return (
    <section className="grid overflow-hidden md:h-[28.75rem] md:grid-cols-[6fr_4fr] md:rounded-b-[0.25rem]">
      <div className="relative min-h-80 md:min-h-[auto]">
        <Image
          src={t("homepage:homepage-marketing-block-copy-image")}
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          alt="Marketing"
        />
      </div>
      <div className="flex flex-col items-center justify-center space-y-4 bg-flavour-50 px-4 py-10 text-center md:px-10 md:py-4">
        <HeartIcon className="h-10 w-10 text-attention-500" />
        <Heading as="h2">
          {t("homepage:homepage-marketing-block-copy-title")}
        </Heading>
        <Body>{t("homepage:homepage-marketing-block-copy-text")}</Body>
        <LokaliseMarkdownText
          lokaliseKey="homepage:homepage-marketing-block-copy-link"
          wrapper={Fragment}
          overrides={{
            a: {
              component: LinkButton,
              props: {
                appearance: "minimal",
                onClick: handleMarketingClick,
              },
            },
          }}
        />
      </div>
    </section>
  );
};
