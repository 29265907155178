import { useTranslation } from "next-i18next";
import { Heading } from "@/ui/cva/Heading";
import { LinkButton } from "@/ui/next/LinkButton";
import { HIW_SELLING_ROUTE } from "@/common/static/routes.static";
import PlusIcon from "@/assets/icons/24/Plus";
import { cn } from "@/common/utils/css.utils";
import { analyticsService } from "@/common/services/analytics/analytics.service";

type CreateAdBlockProps = {
  className?: string;
};

export const HomepageCreateAdBlock = ({ className }: CreateAdBlockProps) => {
  const { t } = useTranslation();

  const handleCreateAdClick = () => {
    /** Trigger `hp_sell_now` event. */
    analyticsService.trackHpSellNow();
  };

  return (
    <section
      className={cn(
        "relative flex flex-col items-center justify-center space-y-4 bg-brand-700 bg-none bg-[length:100%_100%] bg-no-repeat px-4 py-6 md:mt-6 md:flex-row md:space-x-8 md:space-y-0 md:rounded-t-[0.25rem] md:px-4 md:py-4",
        className
      )}
    >
      <div className="flex items-start space-x-2">
        <Heading as="h4" className="text-pretty text-center text-white">
          {t("homepage:homepage-create-ad-block-title-v2")}
        </Heading>
      </div>
      <LinkButton
        href={HIW_SELLING_ROUTE}
        size="large"
        rightIcon={<PlusIcon className="h-3 w-3 shrink-0 text-attention-500" />}
        className="whitespace-nowrap"
        onClick={handleCreateAdClick}
      >
        {t("home:homepage-sell-now-button")}
      </LinkButton>
    </section>
  );
};
